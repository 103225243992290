import React, { FunctionComponent, useEffect, PropsWithChildren, useState, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDebounce } from 'use-debounce';
import { JumpTabsProvider } from '../../../contexts/jump-tabs/jump-tabs.context';
import { buildJumpTabButtonProps, getJumpTabAnchor, getUnderlineSettings } from '../../../helpers/jump-tabs/jump-tabs.helpers';
import { useJumpTabs } from '../../../hooks/jump-tabs/jump-tabs.hooks';
import { JumpTabNavButton } from '../../../types/jump-tabs.types';
import { TextButton } from '../../buttons';
import { DropdownMenu } from '../dropdown-menu/dropdown-menu.component';
import { dropDownShadow, jumpTabButton, jumpTabSlideInAnimation } from './jump-tabs.css';

const TAB_SELECT_DEBOUNCE_DELAY = 300;
const BUTTON_CONTAINER_LEFT_PADDING = 24;

type NavBehavior = 'sticky' | 'sticky-scroll' | 'scroll';

type JumpTabButtonProps = {
	label: string;
	selected: boolean;
	onClick: () => void;
	id: string;
};

export const JumpTabButton: FunctionComponent<JumpTabButtonProps> = ({ label, selected, onClick, id }) => (
	<div className="pr5-l pr4-m">
		<TextButton
			color="grey-darker"
			className={`f5 pa1 pa0-ns outline-0 ${selected ? 'b' : 'b-ns'} ${!selected ? jumpTabButton : ''}`}
			onClick={onClick}
			id={id}
			testId={id}>
			{label}
		</TextButton>
	</div>
);

export type JumpTabsListProps = {
	navBehavior: NavBehavior;
};

export const JumpTabsList: FunctionComponent<JumpTabsListProps> = ({ navBehavior }) => {
	const { ref: stickyNavRef, inView: showStickyNavInView } = useInView({ initialInView: true });
	const [buttonSettings, setButtonSettings] = useState({
		width: '0px',
		marginLeft: '0px'
	});

	const { selectedTab, setSelectedTab, tabs } = useJumpTabs();

	const buttonContainerRef = useRef<HTMLInputElement>(null);

	const [debouncedSelectedTab] = useDebounce(selectedTab, TAB_SELECT_DEBOUNCE_DELAY);

	useEffect(() => {
		const extraPadding = buttonContainerRef.current?.offsetLeft || 0;
		setButtonSettings(getUnderlineSettings(debouncedSelectedTab, extraPadding + BUTTON_CONTAINER_LEFT_PADDING));
	}, [debouncedSelectedTab]);

	const isSelected = (button: JumpTabNavButton) => selectedTab.id === button.id;

	const onButtonClick = (tab: JumpTabNavButton) => setSelectedTab(tab);

	const stickyScrollClass = navBehavior === 'sticky-scroll' && !showStickyNavInView ? jumpTabSlideInAnimation : '';
	const navBehaviorClass = navBehavior === 'sticky' ? jumpTabSlideInAnimation : navBehavior === 'sticky-scroll' ? stickyScrollClass : '';

	return (
		<>
			<div ref={stickyNavRef}></div>
			{/* The div below is introduced to offset the height of the menu. It becomes necessary when the menu's position property is switched to 'fixed', as it removes the menu from the document flow, causing layout shifts. */}
			{!showStickyNavInView && <div style={{ height: '69px' }} data-testid="navbar-placeholder"></div>}
			<div
				className={`flex flex-row justify-between bg-theme-white pv2-ns ph2-m ph4-l w-100 z-3 ${navBehaviorClass}`}
				data-testid="nav-bar"
				ref={buttonContainerRef}>
				<div className="dn flex-l flex-column pv3-l">
					<div className="flex-ns flex-nowrap w-100">
						{tabs.map((button, idx) => (
							<JumpTabButton
								key={idx}
								label={button.label}
								selected={isSelected(button)}
								onClick={() => onButtonClick(button)}
								id={button.id}
							/>
						))}
					</div>
					{/* This div's purpose is to house the sliding underline bar.
		The transition setting provides for a more visually pleasing experience */}
					<div className="w-100">
						<div
							data-testid="underline"
							className={`bb-ns bw1-ns`}
							style={{
								// width and margin-left (relative to the target button) are both available from the button dom element,
								// and are used here after calculation to make the sliding bar have the proper width and positioning.
								width: buttonSettings.width,
								marginLeft: buttonSettings.marginLeft,
								transition: 'all 0.5s'
							}}
						/>
					</div>
				</div>
				<div className="flex items-center mh2 w-100 dn-l ph2 h3">
					<DropdownMenu
						title={selectedTab.label}
						shouldCloseOnChoice={true}
						containerClassName="bg-theme-white theme-black pointer relative w-100 z-3"
						itemClassName="bt-0"
						menuSelectClassName="pv1 flex justify-between bb b--theme-black"
						dropdownClassName={`bw0 pl3 pv2 ${dropDownShadow}`}>
						{tabs.map((button, idx) => (
							<JumpTabButton
								key={idx}
								label={button.label}
								selected={isSelected(button)}
								onClick={() => onButtonClick(button)}
								id={button.id}
							/>
						))}
					</DropdownMenu>
				</div>
			</div>
		</>
	);
};

type JumTabProps = {
	tabName: string;
	tabId?: string;
	className?: string;
};

export const JumpTab: FunctionComponent<PropsWithChildren<JumTabProps>> = ({ tabName, tabId, className = '', children }) => {
	const { ref, inView } = useInView({ rootMargin: '0px 0px -25% 0px', threshold: 0.25 });
	const { setSelectedTab } = useJumpTabs();
	const anchor = getJumpTabAnchor({ tabName, tabId });

	useEffect(() => {
		if (inView) {
			setSelectedTab(buildJumpTabButtonProps({ tabName, tabId }), false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inView]);

	return (
		<div id={anchor} className={className} ref={ref}>
			{children}
		</div>
	);
};

export type JumpTabsProps = {
	className?: string;
	navBehavior?: NavBehavior;
};

export const JumpTabs: FunctionComponent<PropsWithChildren<JumpTabsProps>> = ({
	className = '',
	navBehavior = 'sticky-scroll',
	children
}) => {
	const navButtons = React.Children.map(children, (child) => {
		if (React.isValidElement(child)) {
			return buildJumpTabButtonProps(child.props);
		} else {
			return null;
		}
	});

	return (
		<JumpTabsProvider tabs={navButtons || []}>
			<JumpTabsList navBehavior={navBehavior} />
			<div className={className}>{children}</div>
		</JumpTabsProvider>
	);
};
