import { useQuery } from '@apollo/client';
import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { configureRoute } from '../../../utils/routes/configure-route';
import { LandingPageQuery, LandingPageQueryVariables } from '../../__generated__/graphql-client-types';
import { PageLoading } from '../../components/common-components/loading/loading.component';
import { ContentComponentHelper } from '../../components/construct-components/render-helper/render.helper';
import { ContentContainer } from '../../components/content-container/content-container.component';
import { FourOFour } from '../../components/error-components/404/404.component';
import { useTrackPageView } from '../../hooks/analytics/analytics.hooks';
import { LANDING_PAGE } from '../../queries/construct/construct.queries';
import { LandingPageContent } from '../../types/construct.types';
import { TemplateRouter } from './templates/template-router.component';

export const LandingPage: FunctionComponent = () => {
	const { pathname } = useLocation();

	useTrackPageView({ pageName: `landing:${pathname}` });

	const { data, loading } = useQuery<LandingPageQuery, LandingPageQueryVariables>(LANDING_PAGE, {
		variables: { input: { route: pathname } }
	});

	if (loading) {
		return <PageLoading />;
	}

	if (!data?.pageDocument) {
		return <FourOFour pageName="landing" />;
	}

	const pageContent = new ContentComponentHelper<LandingPageContent>(data.pageDocument);

	return (
		<ContentContainer contentId={pageContent.contentId}>
			<TemplateRouter content={pageContent} />
		</ContentContainer>
	);
};

export const RoutedLandingPage = configureRoute({ path: '*', DANGEROUSLY_IS_LIVE: false }, LandingPage);
