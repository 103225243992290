import React, { FunctionComponent, PropsWithChildren, createContext, useState } from 'react';
import { DEFAULT_TAB } from '../../helpers/jump-tabs/jump-tabs.helpers';
import { JumpTabNavButton } from '../../types/jump-tabs.types';

type JumpTabsContextState = {
	tabs: JumpTabNavButton[];
	selectedTab: JumpTabNavButton;
	setSelectedTab: (selectedTab: JumpTabNavButton) => void;
};

const initialState: JumpTabsContextState = {
	tabs: [],
	selectedTab: DEFAULT_TAB,
	setSelectedTab: () => {}
};

export const JumpTabsContext = createContext<JumpTabsContextState>(initialState);

type JumpTabsProviderProps = {
	tabs: JumpTabNavButton[];
};

export const JumpTabsProvider: FunctionComponent<PropsWithChildren<JumpTabsProviderProps>> = ({ children, tabs }) => {
	const [selectedTab, setSelectedTab] = useState(DEFAULT_TAB);

	const value = { selectedTab, setSelectedTab, tabs };

	return <JumpTabsContext.Provider value={value}>{children}</JumpTabsContext.Provider>;
};
