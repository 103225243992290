import { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { JumpTabsContext } from '../../contexts/jump-tabs/jump-tabs.context';
import { scrollToElement } from '../../helpers/general-helper/general-helper';
import { findButtonFromAnchor } from '../../helpers/jump-tabs/jump-tabs.helpers';
import { JumpTabNavButton } from '../../types/jump-tabs.types';

const SCROLL_OFFSET = 75;

export const useJumpTabs = () => {
	const { selectedTab, setSelectedTab: setTab, tabs } = useContext(JumpTabsContext);
	const history = useHistory();
	const { hash } = useLocation();

	useEffect(() => {
		let initTab: JumpTabNavButton | undefined;
		if (hash) {
			initTab = findButtonFromAnchor(hash.slice(1), tabs);
		} else {
			initTab = tabs[0];
		}

		if (initTab) {
			setSelectedTab(initTab, !!hash);
		}
	}, []);

	const setSelectedTab = (tab: JumpTabNavButton, scroll = true) => {
		setTab(tab);
		const anchor = tab.anchor;
		history.push(`#${anchor}`);
		if (scroll) {
			scrollToElement(anchor, SCROLL_OFFSET);
		}
	};

	return {
		tabs,
		selectedTab,
		setSelectedTab
	};
};
