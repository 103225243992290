import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import { generateDataSelector } from '../../../helpers/general-helper/general-helper';
import { handleKeys } from '../../../helpers/keyboard/keyboard.helper';
import { ChevronDownIcon, ChevronUpIcon } from '../../svg/icons.component';
import { MaybeLabel } from '../label/label.component';
import { Overlay } from '../overlay/overlay.component';
import { dropdownOptions } from './dropdown-menu.css';

const SIZE_MAP = {
	DEFAULT: `input`,
	SMALL: `input-small`,
	LARGE: `input-large`
};

const FONT_SIZE_MAP = {
	DEFAULT: `f5`,
	SMALL: `f6`,
	LARGE: `f5`
};

export const DROPDOWN_CLASSES = 'ba br2 theme-grey-darker bg-theme-white pointer b--theme-grey-light relative';

export type DropdownMenuSize = keyof typeof SIZE_MAP | null;

export type DropdownMenuSelectProps = {
	toggle: () => void;
	expanded: boolean;
	size?: NonNullable<DropdownMenuSize>;
	className?: string;
};

export const DropdownMenuSelect: FunctionComponent<PropsWithChildren<DropdownMenuSelectProps>> = ({
	expanded,
	toggle,
	size = 'DEFAULT',
	children,
	className
}) => {
	const customClassName = className ? className : `${SIZE_MAP[size]} ph3 flex justify-between items-center`;
	return (
		<div
			className={`${customClassName}`}
			onClick={toggle}
			onKeyPress={handleKeys(['Enter', ' '], toggle)}
			role="button"
			data-testid="dropdown-button"
			aria-expanded={expanded}
			aria-haspopup="listbox"
			tabIndex={0}>
			{children}
		</div>
	);
};

export type DropdownMenuProps = {
	title: string | JSX.Element;
	shouldCloseOnChoice?: boolean;
	size?: NonNullable<DropdownMenuSize>;
	children: React.ReactNodeArray;
	ariaLabel?: string;
	automationHook?: string;
	contained?: boolean;
	containerClassName?: string;
	itemClassName?: string;
	menuSelectClassName?: string;
	dropdownClassName?: string;
	titlePrefix?: string;
	label?: string;
	boldLabel?: boolean;
	labelHelpTextTooltip?: React.ReactElement | string;
};

export const DropdownMenu: FunctionComponent<DropdownMenuProps> = ({
	title,
	size = 'DEFAULT',
	shouldCloseOnChoice = false,
	ariaLabel,
	contained,
	children,
	automationHook,
	containerClassName = DROPDOWN_CLASSES,
	itemClassName = 'b--theme-grey-light hover-bg-theme-grey-lighter w-100',
	menuSelectClassName,
	dropdownClassName = '',
	titlePrefix,
	label,
	boldLabel,
	labelHelpTextTooltip
}) => {
	const [expanded, setExpanded] = useState<boolean>(false);
	const [selectedIndex, setSelectedIndex] = useState<number>();

	const toggleMenu = () => {
		setExpanded(!expanded);
	};

	const chooseItem = (index: number) => {
		setSelectedIndex(index);
		if (shouldCloseOnChoice) {
			setExpanded(false);
		}
	};

	const expand = expanded ? '' : 'dn';
	const position = contained ? 'w-100' : `absolute ${dropdownOptions}`;
	const height = contained ? `${children?.length > 6 ? 'h5' : ''} overflow-auto` : '';

	return (
		<>
			<MaybeLabel label={label} helpTextTooltip={labelHelpTextTooltip} boldLabel={boldLabel} />
			<div
				className={`${FONT_SIZE_MAP[size]} ${containerClassName}`}
				data-automation={generateDataSelector('select', automationHook)}>
				<DropdownMenuSelect expanded={expanded} toggle={toggleMenu} size={size} className={menuSelectClassName}>
					<span>
						{titlePrefix}
						{title}
					</span>
					{expanded ? <ChevronUpIcon className="flex-end ml2" /> : <ChevronDownIcon className="flex-end ml2" />}
				</DropdownMenuSelect>
				{expanded && !contained && <Overlay style="neutral" onClick={toggleMenu} zIndex="1" />}
				<ul
					role="listbox"
					aria-label={ariaLabel}
					className={`${position} ${height} ${expand} list pa0 ma0 z-2 bg-theme-white ba br2 br--bottom theme-grey-darker bg-theme-white b--theme-grey-light ${dropdownClassName}`}
					data-testid="menu-list">
					{children &&
						children.map((option, index) => (
							<li
								role="option"
								aria-selected={index === selectedIndex}
								onClick={() => chooseItem(index)}
								onKeyPress={handleKeys(['Enter', ' '], () => chooseItem(index))}
								key={index}
								className={`${index === 0 ? '' : 'bt'} ${itemClassName} flex items-center`}>
								{option}
							</li>
						))}
				</ul>
			</div>
		</>
	);
};

export type DropdownItemProps = {
	title?: string;
	children: JSX.Element;
	tabIndex?: number;
	withoutPadding?: boolean;
};

export const DropdownMenuItem: FunctionComponent<DropdownItemProps> = ({ title, children, tabIndex = -1, withoutPadding = false }) => {
	if (title) {
		const triggerElement = (
			<div className="pa2" data-automation={generateDataSelector('button', title)}>
				{title}
			</div>
		);
		return React.cloneElement(children, { triggerElement, ...children.props });
	}

	return (
		<div className={`${withoutPadding ? 'pa0' : 'pv2 ph4'} w-100`} tabIndex={tabIndex}>
			{children}
		</div>
	);
};
